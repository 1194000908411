const pages = [
    '/ru/my/accounts/transfers/',
    '/ru/my/balance/referrals/'
]

const changeBreadcrumbsView = () => {
    if (pages.includes(document.location.pathname)) {
        document.querySelector('.breadcrumb-container').classList.add('breadcrumb-transfers-container')
        document.querySelector('.breadcrumb-container nav').classList.add('container', 'px-0')
    }
}

export default changeBreadcrumbsView
